.my-table {
  max-width: 100%;
  overflow-y: auto;
}

/* .my-table,
.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
} */

.wrapper {
  display: block;
  max-width: 100%;
  border-bottom: 1px solid black;
}

table {
  /* Make sure the inner table is always as wide as needed */
  width: 100%;
  border-spacing: 50px 0;
  border-collapse: collapse;
  table-layout: auto;
}



table tr:last-child td {
  border-bottom: 0;
}

table th,
table td {
  margin: 0;
  padding: 1px 0.5rem;
  /* padding: 1px; */
  /*border-bottom: 1px solid black;
  border-right: 1px solid black;*/
  border-bottom: 1px solid #b4b2b2;
  /* font-size: 15px; */
  /* The secret sauce */
  /* Each cell should grow equally */
  width: 1%;
}

/* But "collapsed" cells should be as small as possible */
table th.collapse,
table td.collapse {
  width: 0.0000000001%;
}

table th:last-child,
table td:last-child {
  border-right: 0;
}

table th {
  text-align: left;
  /* font-size: 12px; */

  overflow: hidden;
  white-space: nowrap;
}

table tr:hover {
  background-color: #edebeb;
}

table tr td small {
  opacity: 0.1;
}

table tr:hover td small {
  opacity: 1;
}

table th:hover {
  background-color: #a09d9d
}

table td {
  overflow: hidden;
}